@font-face {
  font-family: LibreFranklin-ExtraBoldItalic;
  src: url("./LibreFranklin-ExtraBoldItalic.ttf");
}

body {
  margin: 0;
  cursor: default;
  user-select: none;
  background-color: rgb(201, 201, 200);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo-left {
  font-family: LibreFranklin-ExtraBoldItalic;
  font-size: 64px;
  background: url(stripes.svg) 0 0 / cover repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: .8;
  margin-right: 4px;
}

.logo-right {
  font-family: LibreFranklin-ExtraBoldItalic;
  font-size: 64px;
}